import * as React from "react"
import "/src/css/list.css"
import Block from "/src/components/header-002"
import BlockVideoWrestling from "/src/components/call-to-action-008"


export default function Example() {
  return (
    <>
    <Block
      minH="500|0"
      branding={{
        name: "A1",
      }}
      links={[
        {
          title: "SOFTBALL HIGHLIGHTS",
          href: "/softball-highlights",
        },
        {
          title: "SCHEDULE",
          href: "/softball-schedule",
        },
        {
          title: "ACCOMPLISHMENTS",
          href: "/accomplishments-awards",
        },
        {
          title: "2ND SPORT WRESTLING",
          href: "/wrestling-highlights",
        },
      ]}
      buttonLink={{
        title: "NCAA ID# 2009918132",
        href: "#",
      }}
    />
    <BlockVideoWrestling
    py="20"
      subheading="VIDEO"
      heading="Video Highlights"
      text="YouTube video clips from A1's High School Wrestling. A1 is in the gray and blue singlet. "      
      />
    
    </>
  )
}